
*{ font-family: 'Work Sans', sans-serif; }
h1,h2,h3,h4,h5,h6{ font-family: 'Montserrat', sans-serif; color:#004884; text-align: center; }
a,select,select option,th,td{ color: #004884; text-decoration: none; }
svg path{  color: #ffffff;}


.container-fluid{ padding: 0; }

header{
    width: 100%;
    height: auto;
    overflow: hidden;
}

header nav{ height: 50px; background-color:#36c; text-align: center;  }
header nav img{ height: 100%; } 
/*header nav .img-min{ float: right; margin-right: 100px; }*/
header .bottom-header{ width: 100%; }
header .bottom-header .col-4{ height: 100%;  }
header .bottom-header img{ width:  100%; }
header .col-user{ position: relative; height: 46px; }
header .col-user .content-info{ position: absolute; bottom: 0; right: 0;  }

header .col-user .btnExit{ 
    background-color: #f42f63; 
    border-radius: 25px; 
    padding:6px 16px; 
    color:#ffffff; 
    cursor: pointer; 
    float:right;
    color:#ffffff;
} 

header .col-user .btnExit span{ float: right; padding-left: 16px; color: #ffffff; }
header .col-user .username{ float:right;  color: #004884; margin-right: 16px; line-height: 2; font-size: 18px; }
header .content-bread{ padding-left: 40px; }
header .content-bread .link-bread{ margin-left: 8px; }
header .content-bread span{ margin-left: 8px; color:#BABABA; }
header .content-bread .active{ font-weight: bold; }
header .content-bread .active span{ display: none; }


.parent-component{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    /*display: table;*/
    padding-top: 20px;
    padding-bottom: 50px;
}

.parent-component .btn{
    padding-right: 16px;
    padding-left: 16px;
    border-radius: 25px;
}

.parent-component .return{ float: right; background-color: #069769; border-color: #069769; margin-right: 1rem; }

.parent-component .h1-vedas{ font-Weight:'bold'; margin-top:'20px';  font-size: 26px; }
.parent-component .h2-history{ font-size: 26px; }
.parent-component .h4-history{ font-size: 24px; }


.cnt-table{ 
    width: 100%; 
    padding-left: 16px; 
    overflow: auto; 
    padding-right: 16px; 
    margin-bottom: 25px; 
    height: 400px;
}
.cnt-table .veda-col-1{ min-width: 100px; }
.cnt-table .veda-col-2{ min-width: 300px; }
.cnt-table .veda-col-3{ min-width: 200px; }
.cnt-table .veda-col-4,.cnt-table .veda-col-5{ min-width: 110px; }

.cnt-table .safe-col-1,.cnt-table .safe-col-6{ min-width: 80px; }
.cnt-table .safe-col-2,.cnt-table .safe-col-3{ min-width: 200px; }
.cnt-table .safe-col-4{ min-width: 95px; }
.cnt-table .safe-col-5{ min-width: 150px; }
.cnt-table .safe-col-7{ min-width: 100px;}

/*forms login and register*/

.icon-home{ margin-bottom: 20px; }

.parent-form{
    position:relative;
    width: 90%;
    margin: auto;
    right: 0;   
    left: 0;
    max-width: 360px;   
    margin-top: 20px;
}    

.parent-form form{
    border:1px solid #cacaca;
    padding:20px 14px 16px 14px;
    width: 320px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    box-shadow: 4px 4px 10px grey ;
}

.parent-form label{ margin-bottom: 24px; width: 100%; text-align: left; color: #004884 !important; }
.parent-form input[type="text"],.parent-form input[type="password"]{ margin-top:8px; color:#0B457F; 
    border:none; background-color:#E5EEFB ;
    border-radius: 0;
    border-top-left-radius:0.3rem ; border-top-right-radius: 0.3rem; border-bottom: 1px solid #004884;
}

.parent-form input[type='text']:disabled{ background-color: #f2f2f2; }

.parent-form input[type="checkbox"]{ margin-right: 8px; margin-top: 6px; float: left;  }
.parent-form .cnt-privacy{ overflow: hidden; font-size: 14px; text-align: left; }
.parent-form .cnt-privacy a{ font-size: 14px; font-weight: bold; }
.parent-form .btn{ margin-bottom: 20px; padding-left: 16px; padding-right: 16px; border-radius: 25px; background-color:#008e65 ; } 


.parent-form .rec-input{ margin-top: 10px; color: #004884; cursor: pointer; text-decoration: underline; }
.parent-form .rec-input:hover{ color: #0a58ca; }
.parent-form .error-form{ color:#e70000; text-align: center; margin-top: 4px; font-size: 14px; }

.cnt-btn{
    text-align: center;
    margin-top:20px;
    width: 100%;
    margin: auto;
    width: 250px;
    margin-top: 30px;

} 

.cnt-btn .btn{ 
    width: 100%; 
    box-shadow: 4px 4px 10px grey ; 
    background-color: #069769 !important;
    border-color: #069769;
    border-radius:25PX; 
    padding-right: 16px; 
    padding-left: 16px; 
    text-align: left; 
}

.cnt-btn .btn span{ float: right; color:#fff; }

.cnt-btn.info{ margin-top: 4px; }

.parent-form .btn-vedas{
    text-align: center;
    background-color: #069769;
    color: #fff;
    width: auto;
}

.parent-form .btn-info{
    background-color:#0d6efd !important;
    color: #fff;
    width: auto;
}




.safe-content{ min-width: 200px; width: 100%;  }

.safe-content .row{ width: 100%; margin: 0; }

.safe-form-parent{ width: 90%; margin:auto; margin-bottom: 30px; border:1px solid; border-radius: 5px; padding-top: 20px; }

.safe-form-parent .head-acordion{ border-bottom: 1px solid rgba(0,0,0,0.15);  background-color: #ebebeb; }


.safe-form-parent label{ margin-bottom:10px; font-size: 14px; margin-top: 14px; }
.safe-form-parent label svg{ cursor: pointer; }
.safe-form-parent label svg path{ color: #004884; }

.safe-form-parent .label-head{font-size: 20px; font-weight: bold; } 
.safe-form-parent label span{ margin-left: 10px; }

.safe-form-parent .label-full{ width: 100%;  }

.safe-form-parent input[type='text'],.safe-form-parent input[type='date'],.safe-form-parent select,.safe-form-parent textarea,
.safe-form-parent input[type='number']
{ width: 100%; 

    color:#0B457F; 
    border:none; background-color:#E5EEFB ;
    border-radius: 0;
    border-top-left-radius:0.3rem ; border-top-right-radius: 0.3rem; border-bottom: 1px solid #004884;
}

.safe-form-parent input[type='text']:disabled,.safe-form-parent input[type='date']:disabled{ background-color: #f2f2f2; }
.safe-form-parent  .parent-dir{ padding-top: 12px; padding-bottom: 8px; position: relative; }
.safe-form-parent  .parent-dir .item-dir{
    background-color: #f2f2f2;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    float: left;
    border-bottom: 1px solid #004884;
    padding: 2px 8px;
}

.safe-form-parent .check-custom{ position: relative; padding-left: 24px; }
.safe-form-parent .check-custom input[type='checkbox']{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.safe-form-parent .check-custom .checkmark{
    position: absolute;
    top:0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #f2f2f2;
    border-radius: 5px;
    border: 1px solid #004884;
}

.safe-form-parent .check-custom .checkmark svg{ position: absolute; top:2px; left: 2px; display: none; }
.safe-form-parent .check-custom .checkmark svg path{ color: #FFFFFF; }

.safe-form-parent .check-custom input[type='checkbox']:checked ~ .checkmark{ background-color:#069769; }
.safe-form-parent .check-custom input[type='checkbox']:checked ~ .checkmark svg{ display: block; }
.safe-form-parent .transport-preview{ font-weight: bold; }

.safe-form-parent .parent-input-search{ width: 100%; position: relative; }
.safe-form-parent .parent-input-search .view-search{ 
    position:absolute; 
    width: 100%;
    left:0;
    top:100%;
    border:1px solid rgba(0,0,0,0.15);
    background-color: #fff;
    max-height: 320px;
    overflow: auto;
    z-index: 1;
}

.view-search .background{
    background-color: #069769;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.safe-form-parent .parent-input-search .item-search{
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    padding:0 5px 3px;
    cursor: pointer;
}

.safe-form-parent .parent-input-search .item-search span{ font-weight: bold; margin-right: 6px; font-size: 14px; }
.safe-form-parent  .item-search .enabled,.safe-form-parent  .item-search .disabled{ float:right; }
.safe-form-parent  .item-search .enabled svg path { color:#069769; }
.safe-form-parent  .item-search .disabled svg path{ color:#e70000; }

.safe-form-parent .parent-input-search .item-search:hover{
    background-color: #ededed;
}


.safe-form-parent .content-module,.safe-form-parent .content-module-list{ border:1px solid #d3d3d3; padding: 16px 0; border-radius: 3px; }
.safe-form-parent .content-module-list{  height: 250px ; overflow-y: auto; display: block; padding: 6px 4px; }
.safe-form-parent .content-module-list .item,.safe-form-parent .content-module-list .item-selected{ 
    background-color: #cecece; 
    margin-bottom: 5px; 
    padding: 6px 8px; 
    position: relative;
    border:1px solid #cecece;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    cursor: pointer;
}
.safe-form-parent .content-module-list .item-selected{ background-color: #3772FF;}
.safe-form-parent .content-module-list .item .text,.safe-form-parent .content-module-list .item-selected .text{ width:calc( 100% - 34px ); }
.safe-form-parent .content-module-list .item .mark, .safe-form-parent .content-module-list .item-selected .mark { 
    height:100% ; 
    position: absolute; 
    right: 0; 
    top:0;    
    width: 30px;
    background-color: #ffffff;
    text-align: center;
    border-top-right-radius: 0.3rem;
}
.safe-form-parent .content-module-list .item-selected .text{ color:#ffffff; }
.safe-form-parent .content-module-list .item-selected .mark{ background-color: #00ac7b; }
.safe-form-parent .btn-add,.safe-form-parent .btn-save,.safe-form-parent .btn-remove,.safe-form-parent .btn-resolution,
.safe-form-parent .btn-download,.safe-form-parent .btn-exit{ 
    color:#ffffff;
    width: auto; 
    margin-right: 16px; 
    margin-left: 16px; 
    background-color: #3772FF; 
}


.safe-form-parent .btn-save{ background-color: #008e65;}
.safe-form-parent .btn-save-disabled{ background-color: #BABABA; border-color: #BABABA; }
.safe-form-parent .btn-exit{ background-color: #f42f63;  } 
.safe-form-parent .btn-remove { background-color:#f42f63; width: 54px; height: 34px; }
.safe-form-parent .btn-resolution{ width: 86%; margin-bottom: 10px; }
.safe-form-parent .modal-view{ display: block;}
.safe-form-parent .modal-resolutions{ width:250px ; }
.safe-form-parent .close{
    position: absolute;
    right: 10px;
    top: 8px;
    width: 22px;
    height: 22px;
    padding: 0;
    font-weight: bold;
    font-size: 20px;
    border: none;
    background: none;
    z-index: 1;
}

.safe-form-parent .transport-check{ display: none; }
.safe-form-parent .transport-true{ display: block; }
.safe-form-parent .error-form{ color:#e70000; text-align: center; margin-top: 4px; font-size: 14px; }
.safe-form-parent .error-form-left{ text-align: left; }
.modal-view .modal-body{ padding-bottom: 0;  }
.modal-view .modal-footer{ padding-top: 0; }
.modal-view .modal-footer .btn-bottom{ text-align: center; margin: 8px 0; min-width: 110px; }

.cnt-history{ width: 100%; overflow: auto; }
.cnt-history .cnt-select{ width: 100%; margin:20px 0; }

.cnt-history select
{
    width: 200px; 
    margin:auto;
    color:#0B457F; 
    border:none; background-color:#E5EEFB ;
    border-radius: 0;
    border-top-left-radius:0.3rem ; border-top-right-radius: 0.3rem; border-bottom: 1px solid #004884;
}

.parent-component .btn-table,.parent-component .btn-table-remove{
    padding-right: 16px;
    border-radius: 25px;
    margin:auto;
    color: #0d6efd;
    border-color: #0d6efd;
    padding-left: 25px;
} 
.parent-component .btn-table-remove{ background-color:#f42f63; border-color: #f42f63;}

.cnt-history .col-sal-1{ min-width:140px ; }
.cnt-history .col-sal-2{ min-width:100px ; text-align: center; }
.cnt-history .col-sal-3{ min-width:115px ; text-align: center; }
.cnt-history .col-sal-4{ min-width:100px ;  text-align: center; }
.cnt-history .col-sal-5{ min-width:240px ; }
.cnt-history .col-sal-6{ min-width:140px ; }
.cnt-history .col-sal-7{ min-width:260px ; }
.cnt-history .col-sal-8{ min-width:140px ; }
.cnt-history .col-sal-9{ min-width:240px ; }
.cnt-history .col-sal-10{ min-width:240px ; }
.cnt-history .col-sal-11{ min-width:260px ; }
.cnt-history .col-scroll{ width: 100%; overflow: auto; max-height: 220px; } 

.cnt-history .cnt-module{  border:1px solid; margin-bottom: 8px; padding:5px;}
.cnt-history label{ font-weight: 600; margin-right: 4px; }

.cnt-config{ padding-left: 1rem; padding-right: 1rem; }

/* modal*/
.swal2-html-container{ margin: 30px 0 0;  }

.swal2-html-container input[type='text']{

    width: 100%; 
    color:#0B457F; 
    border:none; background-color:#E5EEFB ;
    border-radius: 0;
    border-top-left-radius:0.3rem ; border-top-right-radius: 0.3rem; border-bottom: 1px solid #004884;
    margin:0;
    padding: 6px 0.75rem;
    height: 37px;
    font-size: 16px;
    margin-bottom: 20px;
    
}

.swal2-container .swal2-title{ padding: 0.8em 0 0; font-size: 1.4em; }

.swal2-confirm,.swal2-cancel{
    padding-left: 16px; padding-right: 16px;
    border-radius: 25px !important;
    background-color:#3366cc !important;
    display: inline-block;
} 

.swal2-cancel{
    background-color:#4B4B4B !important;
}

.swal2-html-container{ padding-left: 1rem; padding-right: 1rem; }

.modal-transport .row-transport{ margin-bottom: 8px; text-align: left; }
.modal-transport .row-transport label{ width: 100%; font-weight: bold; text-align: left; margin-bottom: 8px; }
.modal-transport .row-transport .transport-preview{ font-weight: bold; }

.MuiTooltip-popper .MuiTooltip-tooltip{ font-size: 0.77rem; } 

.item-link{ margin-bottom: 10px; position: relative; margin-left: 12px; } 
.item-link .icon{ 
    float: left; 
    position: relative;
    border-radius: 50%;
    background-color: #3366cc; 
    text-align: center; 
    margin-right: 5px;  
    width: 22px;
}
.item-link .icon svg{ position: absolute; left: 9px; top:3px;  }
.item-link a{ font-size: 18px; }


@media (min-width: 720px) {

    header nav{ text-align: initial;  }
    header nav .img-min{ float: right; margin-right: 100px; }
    header .bottom-header img{ height: 100%; width: auto; }
    .parent-component{ padding-top: 60px; }
    
    header .col-user{ height: auto;}


    .parent-component .return{ float: right; background-color: #069769; border-color: #069769; margin-right:initial ; }
    .parent-component .return-margin{ margin-right: 1rem; }
    .parent-component .h1-vedas{ font-size: 33px; }
    .parent-component .h2-history{ font-size: calc(1.325rem + .9vw); }
    .parent-component .h4-history{ font-size: calc(1.275rem + .3vw); }

    .safe-form-parent{ min-width: 720px; margin-bottom: 30px; border:1px solid; border-radius: 5px; padding-top: 20px; }

    .modal-view .modal-body{ padding-bottom: 1rem;  }
    .modal-view .modal-footer{ padding-top: 0.75rem; }

    /*modal*/
    .swal2-html-container .swal2-title{ padding: 0.8 1em 0; font-size: 1.875em; }

}
